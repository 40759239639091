import { useSuspenseQueries, useSuspenseQuery } from "@tanstack/react-query"
import { type CustomChartSerie } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { type MetricConfiguration } from "@tokenterminal/tt-analytics-api-types/dist/api/metrics"
import { type Interval } from "@tokenterminal/tt-analytics-api-types/dist/api/zoom"
import {
  type TimeseriesItem,
  type EcosystemTimeseriesItem,
  type EcosystemSortedDataIds,
  type SortedDataIds,
} from "@tokenterminal/tt-types"
import { type GranularityType } from "@tokenterminal/ui/Chart/Chart"
import {
  SERIE_TYPE,
  type SERIE_FORMAT,
} from "@tokenterminal/ui/Chart/ChartOptions"
import {
  type ChartSerieTimeData,
  type ChartSerie,
} from "@tokenterminal/ui/Chart/useHighchartOptions"
import { calculateGranularityUnit } from "@tokenterminal/ui/Chart/utils/calculateGranularityUnit"
import { groupDataBasedOnGranularity } from "@tokenterminal/ui/Chart/utils/groupDataBasedOnGranularity"
import { hideDataBeforeCurrentPeriod } from "@tokenterminal/ui/Chart/utils/hideDataNotInCurrentPeriod"
import { getPercentChangeSeries } from "@tokenterminal/ui/utils/getPercentChangeSeries"
import { useMemo } from "react"
import { toDictionary } from "../../../utils/toDictionary"

import { trpcClient } from "../../../utils/trpc"
import { getYaxisMergePaths } from "../store/chart/yaxis-options-atom"
import { SERIE_TYPES } from "../types"
import { convertApiToZoom } from "../utils/convert-zoom-to-api"

function getMetricApproximation(
  metric: Pick<MetricConfiguration, "static"> | undefined
): "average" | "sum" {
  if (metric?.static) {
    return "average"
  }
  return "sum"
}

function getStack(serieType: `${SERIE_TYPES}`, isPercentageShare: boolean) {
  if (
    serieType !== SERIE_TYPES.BAR_STACKED &&
    serieType !== SERIE_TYPES.AREA_STACKED
  ) {
    return undefined
  }

  return isPercentageShare ? "percent" : "normal"
}

function convertChartTypeToHighcharts(
  chartType: `${SERIE_TYPES}`
): `${SERIE_TYPE}` {
  switch (chartType) {
    case SERIE_TYPES.LINE:
      return SERIE_TYPE.LINE
    case SERIE_TYPES.BAR_STACKED:
    case SERIE_TYPES.BAR_UNSTACKED:
      return SERIE_TYPE.COLUMN
    case SERIE_TYPES.AREA_STACKED:
    case SERIE_TYPES.AREA_UNSTACKED:
      return SERIE_TYPE.AREA
    default:
      return SERIE_TYPE.LINE
  }
}

export const getGroupByName = (
  groupBy:
    | "chain"
    | "project+chain"
    | "market-sector"
    | "project"
    | "product"
    | "product+chain"
    | null
) => {
  return groupBy === "project" ? "projects" : groupBy || "none"
}

export const getInnerKey = (
  groupBy:
    | "chain"
    | "project+chain"
    | "market-sector"
    | "project"
    | "chain-project"
    | "chain-project-product"
    | "chain-market-sector"
    | "product"
    | "product+chain"
    | null,
  key: string,
  data: SortedDataIds[0]
) => {
  let innerKey = ""
  switch (groupBy) {
    case "project+chain":
      innerKey = `${key}:${data.chain_id}-${data.data_id}`

      break
    case "chain":
      if (data.market_sector_id) {
        innerKey = `${key}:${data.market_sector_id}`
      } else if (data.chain_id && data.data_id) {
        innerKey = `${key}:${data.data_id}-${data.chain_id}`
      } else if (data.chain_id) {
        innerKey = `${key}:${data.chain_id}`
      } else {
        innerKey = `${key}:${data.data_id}`
      }
      break
    case "project":
      innerKey = `${key}:${data.data_id}`

      break
    case "market-sector":
      innerKey = `${key}:${data.market_sector_id}`
      break
    case "product":
      innerKey = `${key}:${data.product_id}`
      break
    case "product+chain":
      innerKey = `${key}:${data.product_id}-${data.chain_id}`
      break
    default:
      innerKey = `${key}:aggregated`
      break
  }

  return innerKey
}

const formatLabel = (
  groupBy:
    | "chain"
    | "project+chain"
    | "market-sector"
    | "project"
    | "chain-project"
    | "chain-project-product"
    | "chain-market-sector"
    | "product"
    | "product+chain"
    | null,
  info: {
    data_id?: string | undefined
    name?: string | undefined
    chain_id?: string | undefined
    market_sector_id?: string | undefined
    product_id?: string | null
  },
  project?: {
    name: string
  },
  chains?: Map<
    string,
    {
      symbol: string
      name: string
      logo: string
      chain_id: string
      product_id: string | null
      project_data_id: string
      project_slug: string | null
    }
  >,
  marketSectors?: Map<
    string,
    {
      name: string
    }
  >,
  products?: Map<
    string,
    {
      product_name: string
      data_id: string
    }
  >
) => {
  let formattedLabel = ""
  const product = products?.get(info.product_id!)?.product_name
  const productNameFormatted = `${product ? `${product} - ` : ""}`
  switch (groupBy) {
    case "chain":
      if (info.chain_id && !info.data_id) {
        formattedLabel = `${productNameFormatted}${chains?.get(info.chain_id)?.name}`
      } else if (info.chain_id) {
        formattedLabel = `${productNameFormatted}${project?.name} - (${chains?.get(info.chain_id)?.name})`
      } else if (info.market_sector_id) {
        formattedLabel = `${productNameFormatted}${marketSectors?.get(info.market_sector_id)?.name}`
      } else {
        formattedLabel = `${productNameFormatted}${project?.name}`
      }
      break
    case "project+chain":
      formattedLabel = `${project?.name} - (${chains?.get(info.chain_id!)?.name})`
      break
    case "market-sector":
      formattedLabel = `${marketSectors?.get(info.market_sector_id!)?.name}`
      break
    case "product":
      if (
        info.product_id !== "" &&
        products?.get(info.product_id!)?.product_name
      ) {
        formattedLabel = `${products?.get(info.product_id!)?.product_name}`
      } else {
        formattedLabel = `${project?.name ? `${project?.name} - ` : ""}No Product Specified`
      }
      break
    case "product+chain":
      if (
        info.product_id !== "" &&
        products?.get(info.product_id!)?.product_name
      ) {
        formattedLabel = `${products?.get(info.product_id!)?.product_name} - (${chains?.get(info.chain_id!)?.name})`
      }
      break
    default:
      formattedLabel = `${productNameFormatted}${project?.name ?? info.name ?? info.data_id ?? ""}`
      break
  }

  return formattedLabel
}

export type EcosystemGroupBy =
  | "chain"
  | "project"
  | "chain-project"
  | "chain-project-product"
  | "chain-market-sector"

export const getInnerEcosystemKey = (
  groupBy: EcosystemGroupBy,
  key: string,
  data: EcosystemSortedDataIds[0]
) => {
  let innerEcosystemKey = ""
  switch (groupBy) {
    case "chain":
      innerEcosystemKey = `${key}:${data.data_id}`
      break
    case "chain-project":
      innerEcosystemKey = `${key}:${data.data_id}-${data.breakdown_data_id}`
      break
    case "chain-project-product":
      innerEcosystemKey = `${key}:${data.data_id}-${data.breakdown_data_id}-${data.breakdown_data_product_id}`
      break
    case "chain-market-sector":
      innerEcosystemKey = `${key}:${data.data_id}-${data.market_sector_id}`
      break
    default:
      innerEcosystemKey = `${key}:aggregated`
      break
  }

  return innerEcosystemKey
}

const formatEcosystemLabel = (
  groupBy: EcosystemGroupBy,
  info: {
    data_id?: string
    breakdown_data_id?: string
    breakdown_data_product_id?: string
    market_sector_id?: string
  },
  projects?: Map<
    string,
    {
      name: string
      slug: string
      data_id: string
    }
  >,
  chains?: Map<
    string,
    {
      symbol: string
      name: string
      logo: string
      chain_id: string
      product_id: string | null
      project_data_id: string
      project_slug: string | null
    }
  >,
  marketSectors?: Map<
    string,
    {
      name: string
    }
  >,
  isMultipleProjects?: boolean
) => {
  let formattedLabel = ""
  switch (groupBy) {
    case "project":
    case "chain":
      formattedLabel = `${chains?.get(info.data_id ?? "")?.name}`
      break
    case "chain-project":
      if (isMultipleProjects) {
        formattedLabel = `${chains?.get(info.data_id ?? "")?.name} - (${projects?.get(info.breakdown_data_id!)?.name})`
      } else {
        formattedLabel = `${projects?.get(info.breakdown_data_id!)?.name}`
      }
      break
    case "chain-project-product":
      if (isMultipleProjects) {
        formattedLabel = `${chains?.get(info.data_id ?? "")?.name} - (${projects?.get(info.breakdown_data_id!)?.name}) - (${info.breakdown_data_product_id})`
      } else {
        formattedLabel = `${projects?.get(info.breakdown_data_id!)?.name} - (${info.breakdown_data_product_id})`
      }
      break
    case "chain-market-sector":
      if (isMultipleProjects) {
        formattedLabel = `${chains?.get(info.data_id ?? "")?.name} - ${marketSectors?.get(info.market_sector_id!)?.name}`
      } else {
        formattedLabel = `${marketSectors?.get(info.market_sector_id!)?.name}`
      }
      break
    default:
      formattedLabel = info.data_id ?? ""
      break
  }

  return formattedLabel
}

export function useSeries(
  chartSerieSettings: Array<CustomChartSerie>,
  zoom: Interval,
  granularity: GranularityType,
  showCurrentPeriod?: boolean
) {
  const { data: projects } = useSuspenseQuery({
    queryKey: ["getProjectsList"],
    queryFn: () =>
      trpcClient.projects.getProjects.query().then((res) => res.data),
  })

  const { data: marketSectors } = useSuspenseQuery({
    queryKey: ["getMarketSectorsList"],
    queryFn: () =>
      trpcClient.marketSectors.getMarketSectors.query().then((res) => res.data),
  })

  const { data: chains } = useSuspenseQuery({
    queryKey: ["getChainsList"],
    queryFn: () => trpcClient.chains.getChains.query().then((res) => res.data),
  })

  const { data: products = [] } = useSuspenseQuery({
    queryKey: ["getProductsList"],
    queryFn: () =>
      trpcClient.products.getProducts.query({}).then((res) => res.data),
  })

  const { data: metricsConfiguration } = useSuspenseQuery({
    queryKey: ["getMetricsConfiguration"],
    queryFn: () =>
      trpcClient.metrics.getMetricsConfiguration
        .query()
        .then((res) => res.data),
  })

  const { data: ecosystemIds = [] } = useSuspenseQuery({
    queryKey: ["getMetricsEcosystemsAvailability"],
    queryFn: async () => {
      const result = await trpcClient.metrics.getEcosystemAvailability.query({})
      return result.data.map((item) => item.metric_id)
    },
  })

  const results = useSuspenseQueries({
    queries: chartSerieSettings
      .filter((settings) => settings.filters.length > 0)
      .map((chartSerie) => {
        const dataIds: string[] = []
        const chainIds: string[] = []
        const marketSectorIds: string[] = []
        const productIds: string[] = []

        chartSerie.filters.forEach((filter) => {
          if (filter.type === "project") {
            dataIds.push(...filter.values)
          }
          if (filter.type === "blockchain") {
            chainIds.push(...filter.values)
          }
          if (filter.type === "market_sector") {
            marketSectorIds.push(...filter.values)
          }
          if (filter.type === "product") {
            productIds.push(...filter.values)
          }
        })

        const productIdsFilter = productIds.length > 0 ? productIds : undefined

        return {
          queryKey: [
            "getTimeseries",
            chartSerie.metric,
            dataIds,
            chainIds,
            marketSectorIds,
            zoom,
            chartSerie.groupBy,
            productIdsFilter,
          ],
          queryFn: async () => {
            const metricId = chartSerie.metric.replace(/-/g, "_")

            if (ecosystemIds.includes(metricId)) {
              const dimensions =
                chartSerie.groupBy === "project" ||
                chartSerie.groupBy === "project+chain"
                  ? "chain"
                  : chartSerie.groupBy === "market-sector"
                    ? "chain-market-sector"
                    : chartSerie.groupBy

              return trpcClient.metrics.postEcosystemTimeseries.mutate(
                {
                  data_ids: dataIds.length > 0 ? dataIds : undefined,
                  metric_ids: [metricId],
                  interval: zoom,
                  dimensions: dimensions as EcosystemGroupBy,
                },
                {
                  context: {
                    skipBatch: true,
                  },
                }
              )
            } else {
              return trpcClient.metrics.postTimeseries.mutate(
                {
                  data_ids: dataIds.length > 0 ? dataIds : undefined,
                  chain_ids: chainIds.length > 0 ? chainIds : undefined,
                  market_sector_ids:
                    marketSectorIds.length > 0
                      ? marketSectorIds.map((id) => id.replace(/_/g, "-"))
                      : undefined,
                  product_ids: productIdsFilter,
                  metric_ids: [metricId],
                  interval: zoom,
                  groupBy: getGroupByName(
                    chartSerie.groupBy as
                      | "chain"
                      | "project+chain"
                      | "market-sector"
                      | "project"
                      | "product"
                      | "product+chain"
                      | null
                  ),
                },
                {
                  context: {
                    skipBatch: true,
                  },
                }
              )
            }
          },
        }
      }),
  })

  const { series, seriesData } = useMemo(() => {
    const series: Array<ChartSerie> = []
    const seriesData: Record<string, ChartSerieTimeData> = {}

    const metricsConfigurationDictionary = toDictionary(
      metricsConfiguration,
      "slug"
    )

    const projectsDictionary = toDictionary(projects, "data_id")
    const chainsDictionary = toDictionary(chains, "chain_id")
    const marketSectorsDictionary = toDictionary(marketSectors, "id")
    const productsDictionary = toDictionary(products, "product_id")
    const yAxisMap = getYaxisMergePaths(
      chartSerieSettings,
      metricsConfigurationDictionary
    )

    const fetchableChartSerieSettings = chartSerieSettings.filter(
      (settings) => settings.filters.length > 0
    )

    let tempSeries: Array<ChartSerie> = []

    for (const [index, chartSetting] of fetchableChartSerieSettings.entries()) {
      const metricConfig = metricsConfigurationDictionary.get(
        chartSetting.metric
      )!
      const serieType = chartSetting.chart_type
      const highchartSerieType = convertChartTypeToHighcharts(serieType)
      const isAggregated = !chartSetting.groupBy
      const isCumulative = chartSetting.display === "cumulative"
      const isPercentage = chartSetting.display === "percentage"

      let chartInfo = isAggregated
        ? [
            {
              data_id: "aggregated",
              name: "Aggregated",
            },
          ]
        : (results[index]?.data?.sorted_data_ids ?? [])

      const groupByProjectOrProjectAndChain =
        chartSetting.groupBy === "project" ||
        chartSetting.groupBy === "project+chain"

      const hasOnlyBlockchainFilter =
        chartSetting.filters.some((filter) => filter.type === "blockchain") &&
        !chartSetting.filters.some((filter) => filter.type === "project") &&
        !chartSetting.filters.some((filter) => filter.type === "market_sector")

      if (hasOnlyBlockchainFilter && groupByProjectOrProjectAndChain) {
        // TODO: We should be using this data for the chart instead of just the sort order
        chartInfo = chartInfo.filter(
          (item) =>
            !chartSetting.filters
              .find((filter) => filter.type === "blockchain")
              ?.values.includes(item.data_id ?? "")
        )
      }

      const metricId = chartSetting.metric.replace(/-/g, "_")

      chartInfo
        .map((info) => {
          const project = projectsDictionary.get(info.data_id ?? "")

          const projectFilter = chartSetting.filters.find(
            (filter) => filter.type === "project"
          )
          const productFilter = chartSetting.filters.find(
            (filter) => filter.type === "product"
          )

          const isMultipleProjects =
            projectFilter && projectFilter.values.length > 1

          return {
            ...info,
            name: project?.name,
            legendLabel: ecosystemIds.includes(metricId)
              ? formatEcosystemLabel(
                  chartSetting.groupBy as EcosystemGroupBy,
                  info,
                  projectsDictionary,
                  chainsDictionary,
                  marketSectorsDictionary,
                  isMultipleProjects
                )
              : formatLabel(
                  chartSetting.groupBy,
                  {
                    ...info,
                    product_id: productFilter?.values?.[0],
                  },
                  project,
                  chainsDictionary,
                  marketSectorsDictionary,
                  productsDictionary
                ),
          }
        })
        .map((info, index) => {
          const innerKey = ecosystemIds.includes(metricId)
            ? getInnerEcosystemKey(
                chartSetting.groupBy as EcosystemGroupBy,
                chartSetting.id,
                info
              )
            : getInnerKey(chartSetting.groupBy, chartSetting.id, info)

          const serie: ChartSerie = {
            index: index,
            metricId: metricId,
            yAxis: yAxisMap.get(chartSetting.id) ?? chartSetting.id,
            id: chartSetting.id,
            name: innerKey,
            label: isAggregated
              ? chartSetting.title
              : `${info.legendLabel} - ${chartSetting.title || metricConfig.title}`,
            type: highchartSerieType,
            format: metricConfig.format as SERIE_FORMAT,
            groupingApproximation: getMetricApproximation(metricConfig),
            isStatic: metricConfig.static,
            cumulative: isCumulative,
            stack: getStack(serieType, isPercentage),
            percentage: isPercentage,
            visible:
              chartSetting.visible && Array.isArray(chartSetting.visible)
                ? chartSetting.visible.includes(innerKey)
                : true,
            info,
          }

          tempSeries.push(serie)
        })

      results.forEach((result, index) => {
        const settings = fetchableChartSerieSettings[index]!
        const key = settings.id
        const sortedDataIds = !settings.groupBy
          ? [{ data_id: "aggregated" }]
          : result.data.sorted_data_ids

        const metricId = settings.metric.replace(/-/g, "_")

        if (ecosystemIds.includes(metricId)) {
          ;(sortedDataIds as EcosystemSortedDataIds).forEach((data) => {
            const innerKey = getInnerEcosystemKey(
              settings.groupBy as EcosystemGroupBy,
              key,
              data
            )
            let innerData: Array<(typeof result.data.data)[0]> = []

            const resultData = result?.data?.data as EcosystemTimeseriesItem[]

            switch (settings.groupBy as EcosystemGroupBy) {
              case "chain":
                innerData = resultData.filter(
                  (item) => item.data_id === data.data_id
                )
                break
              case "chain-project":
                innerData = resultData.filter(
                  (item) =>
                    item.data_id === data.data_id &&
                    item.breakdown_data_id === data.breakdown_data_id
                )
                break
              case "chain-project-product":
                innerData = resultData.filter(
                  (item) =>
                    item.data_id === data.data_id &&
                    item.breakdown_data_id === data.breakdown_data_id &&
                    item.breakdown_data_product_id ===
                      data.breakdown_data_product_id
                )
                break
              case "chain-market-sector":
                innerData = resultData.filter(
                  (item) =>
                    item.data_id === data.data_id &&
                    item.market_sector_id === data.market_sector_id
                )
                break
              default:
                innerData = result.data?.data
                break
            }

            seriesData[innerKey] =
              innerData
                .map(
                  (item) =>
                    [new Date(item.timestamp).getTime(), item.value] as [
                      number,
                      number | null,
                    ]
                )
                .sort((a, b) => a[0] - b[0]) ?? []
          })
        } else {
          ;(sortedDataIds as SortedDataIds).forEach((data) => {
            const innerKey = getInnerKey(settings.groupBy, key, data)
            let innerData: Array<(typeof result.data.data)[0]> = []

            const resultData = result?.data?.data as TimeseriesItem[]

            switch (settings.groupBy) {
              case "project+chain":
                innerData = resultData.filter(
                  (item) =>
                    item.data_id === data.data_id &&
                    item.chain_id === data.chain_id
                )
                break
              case "chain":
                if (data.market_sector_id) {
                  innerData = resultData.filter(
                    (item) => item.market_sector === data.market_sector_id
                  )
                } else if (data.data_id && data.chain_id) {
                  innerData = resultData.filter(
                    (item) =>
                      item.data_id === data.data_id &&
                      item.chain_id === data.chain_id
                  )
                } else if (data.chain_id) {
                  innerData = resultData.reduce(
                    (acc, item) => {
                      if (item.chain_id === data.chain_id) {
                        const existing = acc.find(
                          (x) => x.timestamp === item.timestamp
                        )
                        if (existing) {
                          existing.value =
                            (existing.value ?? 0) + (item.value ?? 0)
                        } else {
                          acc.push({
                            ...item,
                            value: item.value ?? 0,
                            data_id: item.data_id ?? "",
                          })
                        }
                      }
                      return acc
                    },
                    [] as typeof result.data.data
                  )
                } else {
                  innerData = resultData.filter(
                    (item) => item.data_id === data.data_id
                  )
                }
                break
              case "market-sector":
                innerData = resultData.filter(
                  (item) => item.market_sector === data.market_sector_id
                )
                break
              case "project":
                innerData = resultData.filter(
                  (item) => item.data_id === data.data_id
                )
                break
              case "product":
                innerData = resultData.filter(
                  (item) => item.product_id === data.product_id
                )
                break
              case "product+chain":
                innerData = resultData.filter(
                  (item) =>
                    item.product_id === data.product_id &&
                    item.chain_id === data.chain_id
                )
                break
              default:
                innerData = resultData
                break
            }

            seriesData[innerKey] =
              innerData
                .map(
                  (item) =>
                    [new Date(item.timestamp).getTime(), item.value] as [
                      number,
                      number | null,
                    ]
                )
                .reduce(
                  (acc, [timestamp, value]) => {
                    const existingIndex = acc.findIndex(
                      ([t]) => t === timestamp
                    )
                    if (existingIndex !== -1) {
                      // Add values with the same timestamp
                      const existingValue = acc[existingIndex]?.[1]
                      if (acc[existingIndex]) {
                        acc[existingIndex][1] =
                          (existingValue ?? 0) + (value ?? 0)
                      }
                    } else {
                      acc.push([timestamp, value])
                    }
                    return acc
                  },
                  [] as [number, number | null][]
                )
                .sort((a, b) => a[0] - b[0]) ?? []
          })
        }
      })

      // Filter out any series that doesn't have a legend label
      // Mainly for product group by
      tempSeries = tempSeries.filter(
        (serie) => serie.info?.legendLabel !== undefined
      )

      // // we want to resort based on percentage
      if (
        chartSetting.display === "percentage" &&
        !["bar-stacked", "area-stacked"].includes(chartSetting.chart_type)
      ) {
        const seriesInChart = tempSeries.filter(
          (serie) => serie.id === chartSetting.id
        )

        const data = seriesInChart
          .map((serie) => {
            const seriesDataInChart = seriesData[serie.name]

            return {
              name: serie.name,
              percentage: calculateGranularity({
                serie,
                data: seriesDataInChart ?? [],
                granularity,
                zoom,
                showCurrentPeriod,
              }),
            }
          })
          .filter((serie) => serie.percentage !== null)
          .sort((a, b) => {
            if (!a || !b) return 0
            if (metricConfig.is_lower_value_better) {
              return (a?.percentage ?? 0) - (b?.percentage ?? 0)
            }
            return (b?.percentage ?? 0) - (a?.percentage ?? 0)
          })

        series.push(
          ...tempSeries
            .sort((a, b) => {
              const aIndex = data.findIndex((d) => d?.name === a.name)
              const bIndex = data.findIndex((d) => d?.name === b.name)

              if (aIndex === -1 && bIndex === -1) return 0
              if (aIndex === -1) return 1
              if (bIndex === -1) return -1

              return aIndex - bIndex
            })
            .map((serie, index) => {
              if (chartSetting.colors[index]) {
                serie.color = chartSetting.colors[index]
              }

              return {
                ...serie,
                index,
              }
            })
            .slice(0, chartSetting.limit ?? Infinity)
        )
      } else {
        if (metricConfig.is_lower_value_better) {
          tempSeries = [...tempSeries].reverse()
        }

        series.push(
          ...tempSeries
            .map((serie, index) => {
              if (chartSetting.colors[index]) {
                serie.color = chartSetting.colors[index]
              }
              return {
                ...serie,
                index,
              }
            })
            .slice(0, chartSetting.limit ?? Infinity)
        )
      }

      tempSeries = []
    }

    return { series, seriesData }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chartSerieSettings,
    projects,
    metricsConfiguration,
    chains,
    marketSectors,
    showCurrentPeriod,
  ])

  return {
    series,
    seriesData,
  }
}

const calculateGranularity = ({
  serie,
  data,
  granularity,
  zoom,
  showCurrentPeriod,
}: {
  serie: ChartSerie
  data: ChartSerieTimeData
  granularity: GranularityType
  zoom: Interval
  showCurrentPeriod?: boolean
}) => {
  if (granularity !== "day") {
    data = groupDataBasedOnGranularity(
      data as ChartSerieTimeData,
      {
        approximation: serie.groupingApproximation,
        unit: calculateGranularityUnit(granularity),
      },
      convertApiToZoom(zoom)
    )

    if (!showCurrentPeriod) {
      data = hideDataBeforeCurrentPeriod(
        data as ChartSerieTimeData,
        granularity
      )
    }
  }

  data = getPercentChangeSeries({
    serie,
    serieData: data,
    granularity,
  })

  return data[data.length - 1]?.[1]
}
